import { Component, OnInit, Injector, ViewChild, ElementRef, HostListener } from "@angular/core";
import { AppComponentBase } from "../../../../../shared/common/app-component-base";
import { ActivatedRoute } from "@angular/router";
import { Chart, ChartData } from "chart.js";
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { of } from "rxjs/internal/observable/of";
import { catchError, takeUntil } from "rxjs/operators";
import { SidelineServiceProxy, FundraisersServiceProxy } from '../../../../../shared/service-proxies/service-proxies';
import { Table } from 'primeng/table';
import { Subject } from "rxjs/internal/Subject";
import Fuse from 'fuse.js';
import { SelectItem } from "primeng/api";

@Component({
    selector: 'app-search',
    styleUrls: ['./sideline-report.component.less'],
    templateUrl: './sideline-report.component.html',
    animations: [appModuleAnimation()]
})
export class SidelineReportComponent extends AppComponentBase implements OnInit {
    @ViewChild('dataTable', { static: true }) dataTable: Table;

    fundraiserId: number;
    originalParticipants: any = [];
    fundraiser: any;
    participants: any = [];
    participantTeams: any = [];
    fundraiserProgress: any;
    totalCallsAndTexts: any = {
        totalTexts: -1,
        totalCalls: -1
    };
    totalContacts: number = -1;
    contactGoalPerStudent: number = 20;
    contactGoal: number = 0;
    contactGoalPercentage: string;
    textsGoalPercentage: string;
    callsGoalPercentage: string;
    progressGoalPercentage: string;
    teams: any = [];
    filterText = '';
    teamName: string = '';

    selectedTeams: any = [];

    selectedParticipantTeams: SelectItem[] = [];

    teamResultsChart: Chart;
    isSidelineEnabled: boolean;
    isLoading: boolean;

    fuse: Fuse<any>;

    private readonly onDestroy = new Subject();

    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _sidelineServiceProxy: SidelineServiceProxy,
        private _fundraisersServiceProxy: FundraisersServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(takeUntil(this.onDestroy)).subscribe(async params => {
            if (params?.slug) {
                this._sidelineServiceProxy.getIsSidelineEnabledForFundraiserSlug(params.slug).subscribe(async isSidelineEnabled => {
                    this.isSidelineEnabled = isSidelineEnabled;

                    if (this.isSidelineEnabled) {
                        this._fundraisersServiceProxy.getFundraiserUserInfo(undefined, params.slug, undefined).subscribe(async result => {
                            if (result) {
                                this.fundraiser = result;
                                this.fundraiserId = result.fundraiserId;

                                this.getTeamResults();
                                this.getProgress();
                                this.getParticipantResults();
                            }
                        });
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onSelectedTeamChange($event: { itemValue: any; }) {
        const index = this.selectedTeams.indexOf($event.itemValue);
        if (index > -1) {
            this.selectedTeams.splice(index, 1);
        } else {
            this.selectedTeams = [...this.selectedTeams, $event.itemValue];
        }

        this.onSearch();
    }

    onSearch() {
        if (this.filterText.trim() === '') {
            this.participants = [...this.originalParticipants];
        } else {
            this.participants = this.fuse.search(this.filterText).map(result => result.item);
        }

        if (this.selectedTeams.length > 0) {
            this.participants = this.participants.filter(obj => this.selectedTeams.includes(obj.teamId));
        }
    }

    getParticipantResults(): void {
        this._sidelineServiceProxy.getSidelineParticipantResults(this.fundraiserId).pipe(
            takeUntil(this.onDestroy)
        ).subscribe(results => {
            if (results) {
                this.participants = [...results];
                if (this.originalParticipants.length === 0) {
                    this.originalParticipants = [...results];
                    this.participantTeams = Array.from(
                        new Map(this.participants.map((member: { teamId: any; teamName: any; }) => [member.teamId, { teamName: member?.teamName === null ? 'NULL' : member?.teamName, teamId: member?.teamId }])).values()
                    );
                }
                this.fuse = new Fuse(this.participants, {
                    keys: ['name'],
                    isCaseSensitive: false,
                    includeScore: false,
                    shouldSort: true,
                    includeMatches: false,
                    findAllMatches: false,
                    minMatchCharLength: 1,
                    location: 0,
                    threshold: 0.1,
                    distance: 100,
                    useExtendedSearch: false,
                    ignoreLocation: false,
                    ignoreFieldNorm: false,
	                fieldNormWeight: 1,
                });
                this.contactGoal = this.participants.length * this.contactGoalPerStudent;
                this.totalContacts = results.reduce((acc, val) => acc + val.contacts, 0);
                this.contactGoalPercentage = ((this.totalContacts / this.contactGoal) * 100).toFixed(0);
                
                this.getTotalCallsAndTexts();
            }
        });
    }

    getProgress(): void {
        this._sidelineServiceProxy.getSidelineFundraiserProgress(this.fundraiserId).pipe(
            takeUntil(this.onDestroy)
        ).subscribe(results => {
            if (results) {
                this.fundraiserProgress = results;
                if (this.fundraiserProgress.teamGoal > 0) {
                    this.progressGoalPercentage = (((this.fundraiserProgress.totalDonations + this.fundraiserProgress.totalPaid) / this.fundraiserProgress.teamGoal) * 100).toFixed(0);
                }
                else {
                    this.progressGoalPercentage = '0';
                }
            }
        });
    }

    getTeamResults(): void {
        this._activatedRoute.params.subscribe(params => {
            if (params?.slug) {
                this._sidelineServiceProxy.getSidelineTeamResults(this.fundraiserId).pipe(
                    takeUntil(this.onDestroy)
                ).pipe(
                    catchError(_ => {
                        return of({ count: 0, min: 0, max: 0 } as ChartData);
                    })
                ).subscribe(teamResultsData => {
                    if (teamResultsData) {
                        this.teams = teamResultsData?.labels?.length > 0 ? teamResultsData.labels : [];
                        this.teamResultsChart = new Chart('teamResultsChart', {
                            type: 'bar',
                            data: teamResultsData,
                            options: null
                        });
                    }
                });
            }
        });
    }

    getTotalCallsAndTexts(): void {
        this._sidelineServiceProxy.getSidelineTotalCallsAndTexts(this.fundraiserId).pipe(
            takeUntil(this.onDestroy)
        ).subscribe(results => {
            if (results) {
                this.totalCallsAndTexts = results;
                this.textsGoalPercentage = ((this.totalCallsAndTexts.totalTexts / this.contactGoal) * 100).toFixed(0);
                this.callsGoalPercentage = ((this.totalCallsAndTexts.totalCalls / this.contactGoal) * 100).toFixed(0);
            }
        });
    }

    pageYoffset = 0;
    @HostListener('window:scroll', ['$event']) onScroll(event) {
        this.pageYoffset = window.pageYOffset;
    }
}